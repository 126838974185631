import Swiper from 'swiper/bundle';

(() => {
  const root = document.documentElement;

  const updateViewportSizes = (hard = false) => {
    if (hard) {
      root.style.setProperty(
        '--viewport-static-height',
        `${window.innerHeight}px`
      );
    }

    root.style.setProperty('--viewport-height', `${window.innerHeight}px`);

    root.style.setProperty(
      '--viewport-width',
      `${document.documentElement.clientWidth}px`
    );
  };

  const init = () => {
    updateViewportSizes(true);
    setTimeout(() => {
      updateViewportSizes(true);
    }, 100);

    window.addEventListener('resize', () => {
      updateViewportSizes();

      if (window.innerWidth <= 900) {
        root.dataset.mobileLayout = '';
      } else {
        delete root.dataset.mobileLayout;
      }
    });

    const swiper = new Swiper('.swiper-container', {
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      grabCursor: true,

      autoplay: {
        delay: 5000,
      },
    });

    swiper.autoplay.start();
  };

  document.addEventListener('DOMContentLoaded', init);
})();
